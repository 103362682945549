import React from 'react'
import styled from '@emotion/styled'

const Title = styled.h1`
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  font-size: ${props => (props.small ? '2em' : '3em')};
  font-weight: 600;
  color: ${props => props.theme.colors.primary};
  margin: 0 0 3rem 0;
  margin: ${props =>
    props.small ? '1rem auto 4rem auto' : '0 auto 3rem auto'};
  line-height: 1.2;
  span {
    margin: 0 0 0 0.25em;
  }
  a {
    transition: all 0.2s;
    color: ${props => props.theme.colors.primary};
    &:hover {
      color: ${props => props.theme.colors.text};
    }
  }
`

const PageTitle = props => {
  return <Title small={props.small}>{props.children}</Title>
}

export default PageTitle
